import { ImageIcon, ImageIconV2 } from '@mybridge/icons';
import { IconButton } from '@mybridge/ui/icon-button';
import { Input } from '@mybridge/ui/input';
import { Box, HStack } from '@mybridge/ui/layout';
import { Tooltip } from '@mybridge/ui/tooltip';
import { useContext, useRef, useState } from 'react';
import { PostFormContext } from '../context';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const MediaPicker = ({ ...props }) => {
  const {
    gifs,
    images,
    videos,
    setVideos,
    setImages,
    newFileInputRef,
    handleMediaFilesChange,
    setMediaUpdated,
  } = useContext(PostFormContext);
  const { t } = useTranslator();
  
  const [iconColor, setIconColor] = useState('');

  const handleMouseEnter = () => {
      setIconColor('#ffffff');
  };

  const handleMouseLeave = () => {
    setIconColor('');
  };

  return (
    <HStack 
      spacing={0}>
      <Tooltip
        hasArrow
        placement="top"
        variant="round"
        label={t('createPost.addImagesVideo') ||  "Add Images/Video"}
      >
        <IconButton
          borderRadius="30px"
          padding="6px"
         _hover={{ background: '#3D5A80' }}
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
          isDisabled={
            gifs?.length > 0 || videos?.length > 0 || images?.length >= 5
          }
          onClick={() => newFileInputRef?.current?.click?.()}
        >
          <ImageIconV2 color={iconColor} />
        </IconButton>
      </Tooltip>
      <Box w="0" h="0" overflow="hidden">
        <Input
          multiple
          onChange={handleMediaFilesChange}
          ref={newFileInputRef}
          w={0}
          h={0}
          visibility="hidden"
          type="file"
          accept=".jpg,.jpeg,.png,.mp4,.avi,.mov,.wmv"
        />
      </Box>
    </HStack>
  );
};
